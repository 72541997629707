<template>
  <div>
    <div v-bind="$attrs" flex flex-nowrap items-center>
      <div mr-2.5 max-w-145px flex flex-nowrap items-center>
        <div text="13px black" mr-2 flex-1 truncate font-600 @click="onOpenPopup">
          {{ selectCityName }}
        </div>
        <div h="3" w="3" relative flex items-center justify-center rounded-full bg-black @click="onOpenPopup">
          <ElIcon size="8">
            <ElIconArrowDownBold color="white" />
          </ElIcon>
        </div>
      </div>
      <div h-32px rounded-full bg="#F3F3F3" flex="1 ~ items-center">
        <div ml-4 text="sm #777474" flex items-center>
          <ElIcon>
            <ElIconSearch color="#777474"></ElIconSearch>
          </ElIcon>
        </div>
        <div p="l-2.5" text="sm #777474" flex="1" font="500" leading="9" class="search-input truncate" @click="onOpenAttrPopup">
          <span v-if="!confirmInput" text="xs #8E8E8E">{{ t('key.search.attrs.placeholder') }}</span>
          <span v-else text="#000">{{ confirmInput }}</span>
          <ElIcon v-if="!!confirmInput" color="#aaa" @click.stop="handleClearSearch">
            <ElIconCircleCloseFilled></ElIconCircleCloseFilled>
          </ElIcon>
        </div>
      </div>
    </div>
    <MobileSearchCity v-model="showCityPopup" :loading="loading" :refrehs-on-confirm="refrehsOnConfirm" :page-type="pageType"></MobileSearchCity>
    <MobileSearchAttrs v-model="showAttrPopup" :loading="loading" :refrehs-on-confirm="refrehsOnConfirm" :page-type="pageType" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  refrehsOnConfirm?: boolean
  pageType?: string
}

const props = withDefaults(defineProps<Props>(), {
  refrehsOnConfirm: false,
  pageType: '',
})

const { t } = useI18n()
const filterConditionStore = useFilterConditionsStore()
const { confirmInput, clearSearch } = useSearchAttr()

const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})

const canSearchAttr = computed(() => props.pageType !== 'home')

const { showCityPopup } = useUseHomePopup()
const showAttrPopup = ref(false)
const loading = ref(false)

function onOpenPopup() {
  showCityPopup.value = true
}

function onOpenAttrPopup() {
  if (!canSearchAttr.value) {
    onOpenPopup()
    return
  }
  showAttrPopup.value = true
}

function handleClearSearch() {
  clearSearch()
  navigateToList({ replace: true })
}
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
  padding-right: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .el-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 2rem;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
